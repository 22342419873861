<template>
  <div class="search">
    <div class="header">
      <router-link to="/" class="logo__link"> <Logo class="logo"></Logo></router-link>
      <div class="menu">
        <IconButton class="menu__button button--lg button--icon-white" @click="setOpenMenu(true)">
          <IconMenu></IconMenu>
        </IconButton>
      </div>
    </div>
    <div
      class="search__inner"
      :class="{
        'search__inner--search-active':
          (snippetOpen &&
            (searchSnippets.artworks.length > 0 ||
              searchSnippets.artists.length > 0 ||
              searchSnippets.articles.length > 0 ||
              searchSnippets.events.length > 0)) ||
          searchResult.artworks.list.length > 0 ||
          searchResult.artists.list.length > 0 ||
          searchResult.articles.list.length > 0 ||
          searchResult.events.list.length > 0,
      }"
    >
      <div class="search__input-container" v-click-outside="setSnippetClose">
        <BaseInput
          class="search__input"
          placeholder="Type to search"
          :element="search"
          v-model="search"
          @input="
            event => {
              changeInput(event);
              inputSearchSnippets(event);
            }
          "
          @inFocus="val => (focus = val)"
          @keyup.enter.native="inputSearch"
        ></BaseInput>
        <BaseButton
          class="search__button button--outline-white button--sm"
          text="Search"
          @click="inputSearch"
        ></BaseButton>
        <div
          v-if="
            snippetOpen &&
            (searchSnippets.artworks.length > 0 ||
              searchSnippets.artists.length > 0 ||
              searchSnippets.articles.length > 0 ||
              searchSnippets.events.length > 0)
          "
          class="search__input-container_snippets"
        >
          <div class="search__result-nav" style="padding: 20px 0; justify-content: center">
            <template v-for="(item, index) in snippetNav">
              <BaseButton
                class="search__result-nav-button"
                v-if="item.count > 0"
                :key="`nav-${index}`"
                :class="{ 'button--white': item.active, 'button--outline-white': !item.active }"
                :text="`${item.text} (${item.count})`"
                @click="clickSnippetNav(item.text)"
              ></BaseButton>
            </template>
          </div>
          <template v-if="snippetNav[0].active">
            <ul>
              <li
                v-for="snippet in searchSnippets.artworks"
                @click.stop="
                  changeInput(snippet);
                  inputSearch();
                "
              >
                {{ snippet }}
              </li>
            </ul>
          </template>
          <template v-else-if="snippetNav[1].active">
            <ul>
              <li
                v-for="snippet in searchSnippets.artists"
                @click.stop="
                  changeInput(snippet);
                  inputSearch();
                "
              >
                {{ snippet }}
              </li>
            </ul>
          </template>
          <template v-else-if="snippetNav[2].active">
            <ul>
              <li
                v-for="snippet in searchSnippets.articles"
                @click.stop="
                  changeInput(snippet);
                  inputSearch();
                "
              >
                {{ snippet }}
              </li>
            </ul>
          </template>
          <template v-else-if="snippetNav[3].active">
            <ul>
              <li
                v-for="snippet in searchSnippets.events"
                @click.stop="
                  changeInput(snippet);
                  inputSearch();
                "
              >
                {{ snippet }}
              </li>
            </ul>
          </template>
        </div>
      </div>
      <p class="search__mesage text-sub-title" v-if="!search && !searchResult.result">
        Try for example "Exhibitions" or "Baskets"
      </p>
      <p class="search__mesage text-sub-title" v-else-if="loading && search">Searching...</p>
      <p class="search__mesage text-sub-title" v-else-if="searched && !searchResult.result">
        No Results
      </p>
      <template
        v-if="
          (searchResult.artworks.list.length > 0 ||
            searchResult.artists.list.length > 0 ||
            searchResult.articles.list.length > 0 ||
            searchResult.events.list.length > 0) &&
          !loading
        "
      >
        <div class="search__result-nav">
          <template v-for="(item, index) in nav">
            <BaseButton
              class="search__result-nav-button"
              v-if="item.count > 0"
              :key="`nav-${index}`"
              :class="{ 'button--white': item.active, 'button--outline-white': !item.active }"
              :text="`${item.text} (${item.count})`"
              @click="clickNav(item.text)"
            ></BaseButton>
          </template>
        </div>
        <template v-if="nav[0].active">
          <div class="search__result">
            <router-link
              v-for="(item, key) in searchResult.artworks.list"
              :key="`artwork-${key}`"
              :to="{ path: `/artworks/${item.slug}` }"
              v-slot="{ navigate }"
              custom
            >
              <div class="artwork" @click="navigate">
                <div class="image-box">
                  <img class="image" :src="item.search_image" />
                  <div class="code text-sub-title" v-if="item.sku">{{ item.sku }}</div>
                  <div class="hover-container text-sub-title">Click to View</div>
                </div>
                <h3 class="title text-link">{{ item.title }}</h3>
                <!-- <span class="price text-base">${{ item.price }}</span> -->
                <span class="author text-base">{{ item.author }}</span>
              </div>
            </router-link>
          </div>
          <InfiniteLoading
            v-if="searchResult.artworks.has_more"
            class="infinite-loading"
            @infinite="loadMore"
          ></InfiniteLoading>
        </template>
        <template v-else-if="nav[1].active">
          <div class="search__result">
            <router-link
              v-for="(item, key) in searchResult.artists.list"
              :key="`artist-${key}`"
              :to="{ path: `/artists/${item.slug}` }"
              v-slot="{ navigate }"
              custom
            >
              <div class="artist" @click="navigate">
                <div class="image-box">
                  <img class="image" :src="item.search_image" />
                  <div class="hover-container text-sub-title">Click to View</div>
                </div>
                <h3 class="name text-link">{{ item.full_name }}</h3>
                <div class="country">
                  <span class="text-base" v-for="i in item.regions"
                    >{{ i.title }}<span>,</span></span
                  >
                </div>
              </div>
            </router-link>
          </div>
          <InfiniteLoading
            v-if="searchResult.artists.has_more"
            class="infinite-loading"
            @infinite="loadMore"
          ></InfiniteLoading>
        </template>
        <template v-else-if="nav[2].active">
          <div class="search__result">
            <router-link
              v-for="(item, key) in searchResult.articles.list"
              :key="`article-${key}`"
              :to="{ path: `${item.slug}` }"
              v-slot="{ navigate }"
              custom
            >
              <div class="article" @click="navigate">
                <div class="image-box">
                  <img class="image" :src="item.search_image" />
                  <div class="hover-container text-sub-title">Click to View</div>
                </div>
                <span class="tag text-link">{{ item.type }}</span>
                <h3 class="title text-link" @click="$router.push({ path: `/media/${item.slug}` })">
                  {{ item.title }}
                </h3>
                <span class="data text-sub-title">{{ item.date_published }}</span>
              </div>
            </router-link>
          </div>
          <InfiniteLoading
            v-if="searchResult.articles.has_more"
            class="infinite-loading"
            @infinite="loadMore"
          ></InfiniteLoading>
        </template>
        <template v-else-if="nav[3].active">
          <div class="search__result">
            <router-link
              v-for="(item, key) in searchResult.events.list"
              :key="`events-${key}`"
              :to="{ path: `${item.slug}` }"
              v-slot="{ navigate }"
              custom
            >
              <div class="events" @click="navigate">
                <div class="image-box">
                  <img class="image" :src="item.search_image" />
                  <div class="hover-container text-sub-title">Click to View</div>
                </div>
                <div class="type text-link">{{ item.type }}</div>
                <h3 class="title text-link">{{ item.title }}</h3>
                <span class="data text-sub-title">{{ item.date_published }}</span>
              </div>
            </router-link>
          </div>
          <InfiniteLoading
            v-if="searchResult.events.has_more"
            class="infinite-loading"
            @infinite="loadMore"
          ></InfiniteLoading>
        </template>
      </template>
    </div>
    <Footer :footer="true"></Footer>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ClickOutside from "vue-click-outside";

import BaseInput from "@/components/inputs/BaseInput.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Logo from "@/components/icons/Logo.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    BaseInput,
    IconButton,
    BaseButton,
    IconMenu,
    IconClose,
    Logo,
    Footer,
    InfiniteLoading,
  },
  data() {
    return {
      search: "",
      loading: false,
      searched: false,
      busy: false,
      page: 1,
      focus: false,
      snippetOpen: false,
      snippetNav: [
        {
          active: true,
          text: "ART",
          key: "artworks",
          count: 0,
        },
        {
          active: false,
          text: "ARTISTS",
          key: "artists",
          count: 0,
        },
        {
          active: false,
          text: "ARTICLES",
          key: "articles",
          count: 0,
        },
        {
          active: false,
          text: "EVENTS",
          key: "events",
          count: 0,
        },
      ],
      selectedSnippet: null,
    };
  },
  computed: {
    ...mapGetters("publicapi/search", {
      searchSnippets: "getSearchSnippets",
      nav: "getNav",
      searchResult: "getSearchResult",
      searchVal: "getSearch",
    }),
  },
  async created() {
    const { search } = this.$route.query;

    if (search) {
      this.search = search ?? "";
      await this.apiSearch(this.search);
    }
    if (this.searchVal) {
      this.search = this.searchVal;
      this.timer = setTimeout(() => {
        this.searched = true;
      }, 300);
    }
  },

  unmounted() {
    clearTimeout(this.timer);
    clearTimeout(this.timerSnippets);
  },
  watch: {
    "$route.query.search": function () {
      this.search = this.$route.query.search ?? "";
    },
    focus(val) {
      if (val) {
        this.inputSearchSnippets();
      }
    },
    searchSnippets: function () {
      this.activeSnippetNav();
      if (
        this.searchSnippets.artworks.length === 0 &&
        this.searchSnippets.artists.length === 0 &&
        this.searchSnippets.articles.length === 0 &&
        this.searchSnippets.events.length === 0
      ) {
        this.snippetOpen = false;
      }
    },
  },
  methods: {
    ...mapActions("publicapi/search", ["apiSearch", "apiSearchSnippets"]),
    ...mapMutations("publicapi/search", ["setNavActive", "setNavCreating", "setSearchResult"]),
    ...mapMutations(["setOpenMenu"]),

    changeInput(event) {
      this.search = event;
      this.searched = false;
    },
    activeNav() {
      this.setNavCreating(this.selectedSnippet);
    },
    activeSnippetNav() {
      this.snippetNav.map(item => {
        item.count = this.searchSnippets[item.key].length;
        item.active = false;
      });
      for (const item of this.snippetNav) {
        if (this.selectedSnippet && item.key === this.selectedSnippet) {
          item.active = true;
          break;
        } else if (!this.selectedSnippet && item.count > 0) {
          item.active = true;
          break;
        }
      }
    },
    inputSearch(val) {
      this.loading = true;
      this.searched = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        await this.apiSearch({ search: this.search, page: 1 });
        this.activeNav();
        this.snippetOpen = false;
        this.loading = false;
      }, 200);
    },
    async inputSearchSnippets() {
      clearTimeout(this.timerSnippets);
      this.timerSnippets = setTimeout(async () => {
        if (!this.search) {
          this.snippetOpen = false;
        } else if (this.focus) this.snippetOpen = true;
        await this.apiSearchSnippets({ search: this.search, page: 1 });
      }, 200);
    },
    setSnippetClose() {
      this.snippetOpen = false;
    },
    clickNav(text) {
      this.setNavActive(text);
    },
    clickSnippetNav(textButton) {
      this.snippetNav.map(item => {
        if (item.text === textButton) {
          item.active = true;
          this.selectedSnippet = item.key;
        } else item.active = false;
      });
    },
    async loadMore($state) {
      this.busy = true;
      this.page += 1;
      await this.apiSearch({ search: this.search, page: this.page });
      $state?.loaded();
      this.busy = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  z-index: 1000;
  background-color: $lightBlack;
  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }
    &-enter {
      transform: translateX(100%);
      &-to {
        transform: translateX(0%);
      }
    }
    &-leave {
      transform: translateX(0%);
      &-to {
        transform: translateX(100%);
      }
    }
  }
  &__title {
    text-align: center;
  }
  &__input {
    width: 100%;
    max-width: none;
    margin: 0;
    flex-grow: 1;
    &::v-deep {
      .form-control__input {
        height: 4.4rem;
        padding: 0;
        font-family: $fontFrutigerNeue;
        font-weight: 500;
        font-size: 4rem;
        line-height: 1.25;
        letter-spacing: -1.33px;
        color: $white;
        border: none;
        @media screen and (max-width: $xs) {
          font-size: 3rem;
        }
      }
      .form-control__label {
        font-family: $fontFrutigerNeue;
        font-size: 4rem;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -1.33px;
        color: $white;
        opacity: 0.7;
        background: none;
        padding: 0;
        left: 0;
        @media screen and (max-width: $xs) {
          font-size: 3rem;
        }
      }
      .form-control__input:focus ~ .form-control__label,
      .form-control__input:not(:placeholder-shown) ~ .form-control__label {
        display: none;
      }
    }
    &-container {
      position: relative;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 2rem 0 2rem;
      border-bottom: 1px solid $white;
      width: 100%;
      max-width: calc(100% - 21rem);
      @media screen and (max-width: $xs) {
        max-width: 100%;
      }
      &_snippets {
        width: 100%;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        background: $black;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid #fff;
        max-height: 70vh;
        min-height: 150px;
        z-index: 2;
        ul {
          max-height: 300px;
          padding: 0 10px;
          overflow-y: auto;
          li {
            font-size: 14px;
            font-weight: 400;
            padding: 5px;
            margin-bottom: 10px;
            background-color: transparent;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: rgba($color: $blueDark, $alpha: 0.3);
            }
          }
        }
      }
    }
  }
  &__button {
    @media screen and (max-width: $xs) {
      min-width: 10rem;
      height: 4.5rem;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23rem 10.5rem 3rem;
    flex-grow: 1;
    transition: all 0.5s ease;
    @media (max-width: $xxs) {
      padding: 16rem 25px 3rem;
    }
    &--search-active {
      padding-top: 6rem;
      @media (max-width: $xxs) {
        padding-top: 2.35rem;
      }
    }
  }
  &__icon {
    width: auto;
    height: auto;
  }
  &__mesage {
    padding: 15px 10.5rem 0;
    width: 100%;
    @media screen and (max-width: $xs) {
      padding: 15px 0 0;
    }
  }
  &__result {
    padding-top: 5.5rem;
    padding-bottom: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      padding-top: 6rem;
      grid-template-columns: 1fr;
      grid-row-gap: 7rem;
    }
    &-nav {
      padding-top: 10rem;
      display: flex;
      @media screen and (max-width: $xs) {
        padding-top: 7rem;
        flex-wrap: wrap;
      }
      &-button {
        border-radius: 19px;
        height: 4rem;
        padding: 0 20px;
        margin-right: 10px;
        @media screen and (max-width: $xs) {
          margin-bottom: 10px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    & > .artwork {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          width: 100%;
          height: 30vw;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 39rem;
          }
        }

        .code {
          position: absolute;
          right: 9px;
          bottom: 9px;
          display: flex;
          justify-content: center;
          align-content: center;
          text-transform: uppercase;
          border: 1px solid $white;
          border-radius: 5px;
          //width: max-content;
          background-color: rgba(0, 0, 0, 0.45);
          min-width: 7.2rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
          padding: 0.1rem 1rem;
          z-index: 2;
          max-width: 30%;
          display: -webkit-box;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
        }
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
      }

      // .price {
      //   font-size: 1.8rem;
      //   line-height: 2.8rem;
      // }

      .author {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        word-spacing: 3px;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }
      }
    }
    & > .artist {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
        }
      }

      .image {
        width: 100%;
        height: 30vw;
        @media screen and (max-width: $md) {
          height: 40vw;
        }
        @media screen and (max-width: $xs) {
          height: 39rem;
        }
      }

      .name {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1.2rem;
      }

      .country {
        display: flex;
        flex-wrap: wrap;
        gap: 0 4px;
        margin-top: 0.8rem;
        @media screen and (max-width: $xs) {
          font-size: 1.4rem;
          line-height: 2.5rem;
        }
        span {
          &:last-of-type {
            span {
              display: none;
            }
          }
        }
      }
    }
    & > .article {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }
      .image-box {
        position: relative;

        .image {
          width: 100%;
          height: 30vw;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 39rem;
          }
        }

        .code {
          position: absolute;
          right: 9px;
          bottom: 9px;
          display: flex;
          justify-content: center;
          align-content: center;
          text-transform: uppercase;
          border: 1px solid $white;
          border-radius: 5px;
          //width: max-content;
          background-color: rgba(0, 0, 0, 0.45);
          min-width: 7.2rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
          padding: 0.1rem 1rem;
          z-index: 2;
          max-width: 30%;
          display: -webkit-box;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
        }
      }
      .tag {
        display: flex;
        justify-content: center;
        align-content: center;
        width: max-content;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 5px;
        font-size: 14px;
        line-height: 1.8;
        padding: 0.1rem 1rem;
        margin: 10px 0;
      }
      .title {
        font-size: 3.125rem;
        line-height: 1.3;
        margin-bottom: 12px;
        @media screen and (max-width: $xxl) {
          font-size: 25px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .data {
        font-size: 14px;
        line-height: 1.8;
        text-transform: uppercase;
      }
    }
    & > .events {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }
      .image-box {
        position: relative;
        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }
        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          border-radius: 4px;
        }
      }
      .type {
        display: flex;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 4px;
        width: max-content;
        min-width: 9.7rem;
        margin-top: 10px;
        font-size: 1.4rem;
        line-height: 2.5rem;
        padding: 0.1rem 1rem;
      }
      .title {
        font-size: 3.125rem;
        line-height: 1.3;
        margin-top: 10px;
      }
      .data {
        margin-top: 12px;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
        }
      }
    }
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px 10.5rem 0;
  transition: all 0.3s ease;
  @media only screen and (max-width: $md) {
    padding: 4rem 5rem 2rem;
  }
  @media (max-width: $sm) {
    padding: 40px 25px 15px 25px;
  }
}
.menu {
  display: flex;
  &__button {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    height: 6.4rem;
    width: 6.4rem;
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
    @media (max-width: $xxs) {
    }
  }
  .button:first-of-type {
    margin-right: 4rem;
    @media (max-width: $xxs) {
      margin-right: 1.5rem;
    }
  }
}

.logo {
  height: 6.4rem;
  width: max-content;
}
</style>

